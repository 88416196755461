import React from 'react';
import '../styles/slider.css';
import flex from '../styles/flexbox.module.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Slideshow = ({content, onClick}) => {
    return (
      // <div className="slide-container">
      //   <Carousel showArrows={false} autoPlay={true} infiniteLoop={true} showThumbs={false}>
      //       {content.map((item, index)=>(
      //         <div key={index} className={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter}`}>
      //           <div className={`${flex.half} ${flex.order1}`}>
      //             <h3>{item.title}</h3>
      //             <p>{item.text}</p>
      //             <button className="button" onClick={onClick}>{item.btn}</button>
      //           </div>
      //           <div className={`${flex.half}`}>
      //             <img src={item.img} alt={item.title} className={`${index===0 ? 'height-small' : 'height-normal'}`}/>
      //           </div>
      //         </div>
      //       ))}
      //   </Carousel>
      // </div>
      <section>
        {content.map((item, index)=>(          
          <div key={index} className={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} institucional-container`}>
            {index%2 ===0 ?
              <>
                <div className={`${flex.half} ${flex.order1}`}>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  {/* <button className="button" onClick={onClick}>{item.btn}</button> */}
                </div>
                <div className={`${flex.half}`} style={{textAlign: 'center'}}>
                  <img src={item.img} alt={item.title} className={`${index===0 ? 'height-small' : 'height-normal'}`}/>
                </div>              
              </> :
              <>
                <div className={`${flex.half}`} style={{textAlign: 'center'}}>
                  <img src={item.img} alt={item.title} className={`${index===0 ? 'height-small' : 'height-normal'}`}/>
                </div>
                <div className={`${flex.half}`}>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  {/* <button className="button" onClick={onClick}>{item.btn}</button> */}
                </div>
              </>            
            }
          </div>
        ))}        
      </section>
    )
}

export default Slideshow