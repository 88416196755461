import React, {useState, useContext} from "react"
import { Link } from "gatsby"
import LayoutMaster from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import flex from '../styles/flexbox.module.css'
import section from '../styles/sections.module.css'
import {Section} from '../components/sections'
import '../styles/global.css'
import Slideshow from '../components/slider' 
import {Play} from '../components/icons'
import Modal from '../components/modal'
import {GlobalStateContext} from '../context/GlobalContextProvider'
import QRCode from 'qrcode.react'
// import {isIOS, isAndroid} from "react-device-detect";

function IndexPage({data, location}) {
    const [modalTutorial, setModalTutorial] = useState(false) 
    const [modalAbout, setModalAbout] = useState(false) 
    const state = useContext(GlobalStateContext)
    const {current, home: {about, hiw, testimony, insitutional, managers}} = state.language
    const siteTitle = data.site.siteMetadata.title  
    console.log('state.language: ', state.language);
    return (
      <LayoutMaster location={location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`qzela`, `tecnologia`, `app`, `zeladoria`, `urbana`]}
        />
        <Section className={section.darkNoBottom} flex={`${flex.container} ${flex.alignCenter} ${flex.directionRow} ${flex.spacing}`} contentClass="full-height">
            <section className={`${flex.half} ${flex.selfCenter}`}>
              <h2>{about.title}</h2>
              <p key="0">
                {about.text1}
                <br/>
                {about.text2}
                <br/>
                {about.text3}
              </p>
              <p key="1">
                {about.text4}
              </p>
              <p key="2">
                <a className="button" href="#about">{about.more}</a>
                {/* <button className="button" onClick={()=>window.scroll(0, windowHeight())}>{about.more}</button> */}
              </p>
              <div className={`${flex.container} ${flex.smallSpacing}`}>
                <p key="3" className="qrCode">
                  <QRCode value="https://www.qzela.com.br/qrcode/" size="165" />
                  {/* <img src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/qrcode-qzela.png" width="165" alt=""/> */}
                </p>
                <p className={`${flex.container} ${flex.smallSpacing} ${flex.directionColumn} btns-stores`}>
                  <a href="https://apps.apple.com/br/app/qzela/id1462800175" target="_blank" rel="noopener noreferrer"><img src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/app-store.png" width="165" alt=""/></a>
                  <a href="https://play.google.com/store/apps/details?id=com.westarstg.qzela" target="_blank" rel="noopener noreferrer"><img src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/google-play.png" width="165" alt=""/></a>
                </p>
              </div>
            </section>
        </Section>
        <Section id="about" className={section.grey} flex={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter}`}>
          <div className={`${flex.half}`}>
            {/* <ResponsiveCarousel deviceType="desktop" /> */}
            <button className={`${flex.half} ${section.videoThumb}`} onClick={()=>setModalAbout(true)}>
              <span className={section.playIcon}>
                <Play width="70" height="70" />
              </span>              
              <img src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/qzela-tutorial.png" alt=""/>
            </button>
            {modalAbout && 
              <Modal close={()=>setModalAbout(false)}>
                <iframe title="Tutorial" width="100%" height="100%" src="https://www.youtube.com/embed/w2jii6kec9s?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Modal>
            }
          </div>
          <div className={`${flex.half}`}>
            <h3>{hiw.title}</h3>
            {hiw.text.map((item, index)=>(<p key={index}>{item}</p>))}
          </div>
        </Section>
        <Section id="institutional" className={section.light} flex={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} ${flex.directionColumn}`}>
            <Slideshow content={testimony} onClick={()=>setModalTutorial(true)} />            
            <section className={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} institucional-container`}>
              <div className={`${flex.half}`}>
                <button className={`${section.videoThumb}`} onClick={()=>setModalTutorial(true)}>
                  <span className={section.playIcon}>
                    <Play width="70" height="70" />
                  </span>
                  <img src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/qzela-istitucional.jpg" alt=""/>
                </button>            
                {modalTutorial && 
                  <Modal close={()=>setModalTutorial(false)} video={true}>
                    {
                      current==='portuguese' ? 
                        <iframe title="Institucional" width="100%" height="100%" src="https://www.youtube.com/embed/8c9-VmF95jw?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                        : 
                        <iframe src="https://player.vimeo.com/video/351000392" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    }
                  </Modal>
                }
              </div>
              <div className={`${flex.half}`}>
                  <h3>{insitutional.title}</h3>
                  <p>{insitutional.text}</p>
              </div>
            </section>
        </Section>
        <Section id="gestores" className={section.grey} flex={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter}`}>
            <div className={`${flex.half}`}>
              <h3>{managers.title}</h3>
              <p>{managers.text}</p>
            </div>
            <div className={`${flex.half} ${flex.container} ${flex.spacing} ${flex.justifyCenter} ${flex.alignCenter}`}>
              <Link to="/managers#private" className={`${section.imageCards} ${flex.half} ${flex.container} ${flex.alignEnd}`} style={{backgroundImage: "url('https://storage.googleapis.com/assets.qzela.com.br/images/landing/managers/services.png')"}}>
                <h5>{managers.private} +</h5>
              </Link>
              <Link to="/managers#public" className={`${section.imageCards} ${flex.half} ${flex.container} ${flex.alignEnd}`} style={{backgroundImage: "url('https://storage.googleapis.com/assets.qzela.com.br/images/landing/managers/publico.png')"}}>
                <h5>{managers.public} +</h5>
              </Link>
            </div>
        </Section>
      </LayoutMaster>
    )
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage