import React from "react"
import modal from '../styles/modal.module.css'
import {Close} from '../components/icons'

export default function Modal({children, close, video}){
    return(
        <section className={modal.container}>
            <div className={video ? modal.contentVideo : modal.content}>
                <button className={modal.close} onClick={close}>
                    <Close width="30" height="30" className={modal.fill} />
                </button>
                {children}
            </div>
        </section>
    )
}